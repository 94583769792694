import React from "react";
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { errorBg1 } from "../../../_metronic/assets";

export function ErrorPage1() {
  const history = useHistory();
  const goBack = () => history.goBack();

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${errorBg1})`,
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          OOPS! Something went wrong here
        </p>
        <div>
          <span
            onClick={goBack}
            className="btn-link cursor-pointer font-size-lg"
          >
            <FaArrowLeft className="mr-2" />
            Go back to Home
          </span>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get General Dashboard Stats Data
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getImzStatsApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getStats, {
    params: filters,
  });
}

export function getImmunizationChartApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getImmunizationChart, {
    params: filters,
  });
}

export function getCompletedImmunizationApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getCompletedImmunization, {
    params: filters,
  });
}

export function getIncompletedImmunizationApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getIncompletedImmunization, {
    params: filters,
  });
}
export function getRefusedAnotherFacilityImmunizationsGraphApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getRefusedAnotherFacilityGraphImmunizations, {
    params: filters,
  });
}
export function getLookupsApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getLookups, {
    params: filters,
  });
}
export function getRefusedAnotherFacilityImmunizationsApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getRefusedAnotherFacilityImmunizations, {
    params: filters,
  });
}

export function getMissedImmunizationApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getMissedImmunization, {
    params: filters,
  });
}

export function getChildImmunizationApi(filters) {
  return axios.get(
    API_PATH.IMMUNIZATION_DASHBOARD.getChildImmunizationDetails,
    {
      params: filters,
    }
  );
}
export function getDeleteImmunizationRequestsApi(filters) {
  return axios.get(
    API_PATH.IMMUNIZATION_DASHBOARD.getDeleteImmunizationRequests,
    {
      params: filters,
    }
  );
}
export function approveOrDenyImmunizationDeleteRequestApi(data) {
  return axios.post(
    API_PATH.IMMUNIZATION_DASHBOARD.approveOrDenyImmunizationDeleteRequest, data
  );
}
export function bulkApproveOrDenyImmunizationDeleteRequestApi(data) {
  return axios.post(
    API_PATH.IMMUNIZATION_DASHBOARD.bulkApproveOrDenyImmunizationDeleteRequest, data
  );
}

export function getPatientVaccinationApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.getPatientVaccinations, {
    params: filters,
  });
}

export function exportStatsExcelApi(filters) {
  return axios.get(API_PATH.IMMUNIZATION_DASHBOARD.exportStatsExcel, {
    params: filters,
    headers: {
      "Access-Control-Expose-Headers": "Content-Disposition",
    },
    responseType: "blob",
  });
}

export function exportMissedImmunizationExcelApi(filters) {
  return axios.get(
    API_PATH.IMMUNIZATION_DASHBOARD.exportMissedImmunizationExcel,
    {
      params: filters,
      responseType: "blob",
    }
  );
}

export function exportChildImmunizationsExcelApi(filters) {
  return axios.get(
    API_PATH.IMMUNIZATION_DASHBOARD.exportChildImmunizationsExcel,
    {
      params: filters,
      responseType: "blob",
    }
  );
}

export function exportPatientVaccinationExcelApi(filters) {
  return axios.get(
    API_PATH.IMMUNIZATION_DASHBOARD.exportPatientVaccinationExcel,
    {
      params: filters,
      responseType: "blob",
    }
  );
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addDataSetSetupApi(data) {
  return axios.post(API_PATH.DHIS_DATASET_SETUP.addDataSetSetup, data);
}

/**
 * Get Update Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateDataSetSetupApi(data) {
  return axios.post(API_PATH.DHIS_DATASET_SETUP.updateDataSetSetup, data);
}

/**
 * Delete Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deleteDataSetSetupApi(data) {
  return axios.post(API_PATH.DHIS_DATASET_SETUP.deleteDataSetSetup, data);
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDataSetSetupListApi(data) {
  return axios.get(`${API_PATH.DHIS_DATASET_SETUP.getDataSetSetupList}`, {
    params: data,
  });
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDatabaseLabelListApi(data) {
  return axios.get(API_PATH.DHIS_DATASET_SETUP.getDatabaseLabelList, {
    params: data,
  });
}
export function CheckDHISPushRequestApi(data) {
  return axios.get(
    `${API_PATH.DHIS_DATASET_SETUP.CheckDHISPushRequest}?Month=${data?.Month}&Year=${data?.Year}`
  );
}

import { API_PATH } from "../config";
import axios from "axios";

export function getLocationApi(data) {
    return axios.get(API_PATH.LOCATION.getLocation, {
      params: data,
    });
}

export function getAllLocationApi(data) {
  return axios.get(API_PATH.LOCATION.getAllLocation, {
    params: data,
  });
}
export function getAllDoctorDesignationsApi(data) {
  return axios.get(API_PATH.LOCATION.getAllDoctorDesignation, {
    params: data,
  });
}

export function addLocationApi(data) {
    return axios.post(API_PATH.LOCATION.addLocation, data);
}
import { CHILD_LIST } from "../actions/utilities";

const INITIAL_STATE = {
  getAllChildsLoading: false,
  getAllChildsSuccess: false,
  getAllChildsFailure: false,
  getAllChildsError: null,
  allChilds: [],
  allChildsCount: 0,

  getSingleChildLoading: false,
  getSingleChildSuccess: false,
  getSingleChildFailure: false,
  getSingleChildError: null,
  getSingleChild: {},

  getSingleChildEditLoading: false,
  getSingleChildEditSuccess: false,
  getSingleChildEditFailure: false,
  getSingleChildEditError: null,
  getSingleChildEdit: {},

  updateSingleChildLoading: false,
  updateSingleChildSuccess: false,
  updateSingleChildFailure: false,
  updateSingleChildError: null,
  updateSingleChild: null,
};

export const childReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHILD_LIST.GET_CHILDS_LIST_REQUEST:
      return {
        ...state,
        getAllChildsLoading: true,
        getAllChildsSuccess: false,
        getAllChildsFailure: false,
        getAllChildsError: null,
        allChilds: [],
        allChildsCount: 0,
      };
    case CHILD_LIST.GET_CHILDS_LIST_FAILURE:
      return {
        ...state,
        getAllChildsLoading: false,
        getAllChildsSuccess: false,
        getAllChildsFailure: true,
        getAllChildsError: action.payload,
      };
    case CHILD_LIST.GET_CHILDS_LIST_SUCCESS:
      return {
        ...state,
        getAllChildsLoading: false,
        getAllChildsSuccess: true,
        getAllChildsFailure: false,
        getAllChildsError: null,
        allChilds: action.payload,
        allChildsCount: action.info.count,
      };
    case CHILD_LIST.GET_SINGLE_CHILD_REQUEST:
      return {
        ...state,
        getSingleChildLoading: true,
        getSingleChildSuccess: false,
        getSingleChildFailure: false,
        getSingleChildError: null,
        getSingleChild: {},
      };
    case CHILD_LIST.GET_SINGLE_CHILD_FAILURE:
      return {
        ...state,
        getSingleChildLoading: false,
        getSingleChildSuccess: false,
        getSingleChildFailure: true,
        getSingleChildError: action.payload,
      };
    case CHILD_LIST.GET_SINGLE_CHILD_SUCCESS:
      return {
        ...state,
        getSingleChildLoading: false,
        getSingleChildSuccess: true,
        getSingleChildFailure: false,
        getSingleChildError: null,
        getSingleChild: action.payload,
      };
    case CHILD_LIST.GET_SINGLE_CHILD_EDIT_REQUEST:
      return {
        ...state,
        getSingleChildEditLoading: true,
        getSingleChildEditSuccess: false,
        getSingleChildEditFailure: false,
        getSingleChildEditError: null,
        getSingleChildEdit: {},
        updateSingleChildFailure: false,
        updateSingleChildError: "",
      };
    case CHILD_LIST.GET_SINGLE_CHILD_EDIT_FAILURE:
      return {
        ...state,
        getSingleChildEditLoading: false,
        getSingleChildEditSuccess: false,
        getSingleChildEditFailure: true,
        getSingleChildEditError: action.payload,
        updateSingleChildFailure: false,
        updateSingleChildError: "",
      };
    case CHILD_LIST.GET_SINGLE_CHILD_EDIT_SUCCESS:
      return {
        ...state,
        getSingleChildEditLoading: false,
        getSingleChildEditSuccess: true,
        getSingleChildEditFailure: false,
        getSingleChildEditError: null,
        getSingleChildEdit: action.payload,
        updateSingleChildFailure: false,
        updateSingleChildError: "",
      };
    case CHILD_LIST.UPDATE_SINGLE_CHILD_REQUEST:
      return {
        ...state,
        updateSingleChildLoading: true,
        updateSingleChildSuccess: false,
        updateSingleChildFailure: false,
        updateSingleChildError: null,
        updateSingleChild: null,
      };
    case CHILD_LIST.UPDATE_SINGLE_CHILD_FAILURE:
      return {
        ...state,
        updateSingleChildLoading: false,
        updateSingleChildSuccess: false,
        updateSingleChildFailure: true,
        updateSingleChildError: action.payload,
      };
    case CHILD_LIST.UPDATE_SINGLE_CHILD_SUCCESS:
      return {
        ...state,
        updateSingleChildLoading: false,
        updateSingleChildSuccess: true,
        updateSingleChildFailure: false,
        updateSingleChildError: null,
        updateSingleChild: action.payload,
      };

    default:
      return state;
  }
};

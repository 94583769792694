import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addDatasetConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATASET_CONFIGURATION.addDatasetConfiguration,
    data
  );
}

/**
 * Get Update Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateDatasetConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATASET_CONFIGURATION.updateDatasetConfiguration,
    data
  );
}

/**
 * Delete Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deleteDatasetConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATASET_CONFIGURATION.deleteDatasetConfiguration,
    data
  );
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDatasetConfigurationApi(data) {
  return axios.get(
    API_PATH.DHIS_DATASET_CONFIGURATION.getDatasetConfiguration,
    {
      params: data,
    }
  );
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDataSetMappingSelectListApi(data) {
  return axios.get(
    API_PATH.DHIS_DATASET_CONFIGURATION.getDataSetMappingSelectList,
    {
      params: data,
    }
  );
}

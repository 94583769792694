import {
  request,
  success,
  failure,
  DHIS2_DATA_UPLOAD_REQUEST,
} from "./utilities";
import { getDataUploadApi, pushDataUploadApi, getDhisLoginApi, getDhisPushRequestsApi, genrateDhisPushRequestsApi, getDhisLogsApi } from "../api";

export function getDataUploadAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_REQUEST));
    getDataUploadApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function pushDataUploadAction(data, modalClose, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_REQUEST));
    pushDataUploadApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_SUCCESS,
                response.data.data
              )
            );
            if (modalClose) {
              modalClose();
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_FAILURE,
                response.data.message
              )
            );
            if (modalClose) {
              modalClose();
            }
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDhisLoginAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_REQUEST));
    getDhisLoginApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function getDhisPushRequestsAction(data) {
  return (dispatch) => {
    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_REQUEST));
    getDhisPushRequestsApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_SUCCESS,
                response.data.data
              )
            );
          } else {
            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_FAILURE,
              error.message
            )
          );
        }
      )
  };
}
export function genrateDhisPushRequestsAction(data) {
  return (dispatch) => {
    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_REQUEST));
    genrateDhisPushRequestsApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_SUCCESS,
                response.data.data
              )
            );
          } else {
            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_FAILURE,
              error.message
            )
          );
        }
      )
  };
}
export function getDhisLogsAction(data) {
  return (dispatch) => {
    dispatch(request(DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_REQUEST));
    getDhisLogsApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            dispatch(
              success(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_SUCCESS,
                response.data.data
              )
            );
          } else {
            dispatch(
              failure(
                DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          dispatch(
            failure(
              DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_FAILURE,
              error.message
            )
          );
        }
      )
  };
}


import { GENERIC } from "./actions/utilities";
import { baseURL } from "./config";


export default function setupAxios(axios, store) {

  axios.interceptors.request.use(
    (config) => {
      console.log("config::==>", config);
      const storedState = localStorage.getItem("StateId");
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers.StateId = storedState;
      return config;
    },
    (err) => Promise.reject(err)
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      let user_refresh_token = window.localStorage.getItem("user_refresh_token")
      console.log("user_refresh_token", user_refresh_token);
      // If the response status is 401, it means the user is unauthorized.
      if (response.status === 401) {
       
        // store.dispatch({
        //   type: GENERIC.SET_SESSION_EXPIRE_STATE,
        //   payload: true,
        // }); 
        axios.post(`${baseURL}/account/RefreshToken`, {
          "client_id": "OgowMobile",
          "grant_type": "refresh_token",
          "client_secret": "Ogow@123",
          "refresh_token": user_refresh_token
        })
          .then(response => {
             window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);
            console.log("API call response:", response);
          })
          .catch(apiError => {
            console.error("API call error:", apiError);
          });
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        let user_refresh_token = window.localStorage.getItem("user_refresh_token")
        console.log("user_refresh_token", user_refresh_token);
        // store.dispatch({
        //   type: GENERIC.SET_SESSION_EXPIRE_STATE,
        //   payload: true,
        // });
        axios.post(`${baseURL}/account/RefreshToken`, {
          "client_id": "OgowMobile",
          "grant_type": "refresh_token",
          "client_secret": "Ogow@123",
          "refresh_token": user_refresh_token
        })
          .then(response => {
             window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);
             if (!window.location.pathname.endsWith('/login')) {
              window.location.reload();
      }})

          .catch(apiError => {
            console.error("API call error:", apiError);
          });
      }
      if (error?.response?.status === 500) {
        return Promise.reject("");
      }
      return Promise.reject(error);
    }
  );
}

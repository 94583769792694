import {
  request,
  success,
  failure,
  DHIS2_DATASET_CONFIGURATION_REQUEST,
  CLINICS_REQUEST,
} from "./utilities";
import {
  addDatasetConfigurationApi,
  updateDatasetConfigurationApi,
  deleteDatasetConfigurationApi,
  getDatasetConfigurationApi,
  getDataSetMappingSelectListApi,
  getSingleClinicDetailsApi,
  getgetTagsApi,
  getTagsApi,
} from "../api";

export function addDatasetConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_REQUEST
      )
    );
    addDatasetConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateDatasetConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_REQUEST
      )
    );
    updateDatasetConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function deleteDatasetConfigurationAction(
  data,
  modalClose,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_REQUEST
      )
    );
    deleteDatasetConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_SUCCESS,
                data.id
              )
            );
            if (modalClose) {
              modalClose();
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDatasetConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_REQUEST
      )
    );
    getDatasetConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function getTagsAction(isGetClinics) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_TAGS_LIST_REQUEST));
    getTagsApi(isGetClinics).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_TAGS_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_TAGS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_TAGS_LIST_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getDataSetMappingSelectListAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_REQUEST
      )
    );
    getDataSetMappingSelectListApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

import {
  request,
  success,
  failure,
  DHIS2_DATAELEMENT_CONFIGURATION_REQUEST,
} from "./utilities";
import {
  addDataelementConfigurationApi,
  updateDataelementConfigurationApi,
  deleteDataelementConfigurationApi,
  getDataelementConfigurationApi,
  getDataElementMappingSelectListApi,
} from "../api";

export function addDataelementConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_REQUEST
      )
    );
    addDataelementConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateDataelementConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_REQUEST
      )
    );
    updateDataelementConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function deleteDataelementConfigurationAction(
  data,
  modalClose,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_REQUEST
      )
    );
    deleteDataelementConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_SUCCESS,
                data.id
              )
            );
            if (modalClose) {
              modalClose();
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDataelementConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_REQUEST
      )
    );
    getDataelementConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_SUCCESS,
                response.data.data,
                { count: response.data.totalRecords }

              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDataElementMappingSelectListAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_REQUEST
      )
    );
    getDataElementMappingSelectListApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

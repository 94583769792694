import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Doctor List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllDoctorListApi(filters) {
  return axios.get(API_PATH.DOCTOR.getAllDoctorList, {
    params: filters,
  });
}
/**
 * Get Single Doctor Detail
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function getSingleDoctorDetailsApi(Id) {
  return axios.get(`${API_PATH.DOCTOR.getSingleDoctor}?Id=${Id}`);
}
/**
 * Set Doctor Active
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function setDoctorActiveApi(rec) {
  return axios.post(`${API_PATH.DOCTOR.setDoctorActive}`, rec, {});
}

/**
 * Set Doctor Active
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function addEditDoctorApi(rec) {
  return axios.post(`${API_PATH.DOCTOR.addEditDoctor}`, rec, {});
}

/**
 * Get All Doctors List for Select Filter
 *
 * @return {*} Promise
 */
export function getDoctorsForSelectApi() {
  return axios.get(
    `${API_PATH.DOCTOR.getAllDoctorList}?PageNumber=1&PageSize=1000`
  );
}

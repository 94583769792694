import { request, success, failure, LEARNING_CENTRE } from "./utilities";
import {
  getAllVideosApi,
  addVideoApi,
  deleteVideoApi,
  getLanguageListApi,
} from "../api";

export function getAllVideosAction(data) {
  return (dispatch) => {
    dispatch(request(LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_REQUEST));
    getAllVideosApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function addNewVideoAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_REQUEST));
    addVideoApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function deleteVideoAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_REQUEST));
    deleteVideoApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getLanguageListAction() {
  return (dispatch) => {
    dispatch(request(LEARNING_CENTRE.GET_LANGUAGE_LIST_REQUEST));
    getLanguageListApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              LEARNING_CENTRE.GET_LANGUAGE_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              LEARNING_CENTRE.GET_LANGUAGE_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(LEARNING_CENTRE.GET_LANGUAGE_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

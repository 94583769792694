import { DHIS2_DATA_UPLOAD_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getDhisDataUploadLoading: false,
  getDhisDataUploadSuccess: false,
  getDhisDataUploadFailure: false,
  getDhisDataUploadError: null,
  dhisDataUpload: [],

  pushDhisDataUploadLoading: false,
  pushDhisDataUploadSuccess: false,
  pushDhisDataUploadFailure: false,
  pushDhisDataUploadError: null,
  pushDhisDataUpload: {},

  getDhisLoginLoading: false,
  getDhisLoginSuccess: false,
  getDhisLoginFailure: false,
  getDhisLoginError: null,
  dhisLogin: [],

  getDhisLogsLoading: false,
  getDhisLogsSuccess: false,
  getDhisLogsFailure: false,
  getDhisLogsError: null,
  dhisLogs: [],

  getDhisPushRequestLoading: false,
  getDhisPushRequestSuccess: false,
  getDhisPushRequestFailure: false,
  getDhisPushRequestError: null,
  pushRequests: [],

  genrateDhisPushRequestLoading: false,
  genrateDhisPushRequestSuccess: false,
  genrateDhisPushRequestFailure: false,
  genrateDhisPushRequestError: null,
  genratePushRequest: [],
};

export const dhisDataUploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_REQUEST:
      return {
        ...state,
        getDhisDataUploadLoading: true,
        getDhisDataUploadSuccess: false,
        getDhisDataUploadFailure: false,
        getDhisDataUploadError: null,
        dhisDataUpload: [],
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        getDhisDataUploadLoading: false,
        getDhisDataUploadSuccess: false,
        getDhisDataUploadFailure: true,
        getDhisDataUploadError: action.payload,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        getDhisDataUploadLoading: false,
        getDhisDataUploadSuccess: true,
        getDhisDataUploadFailure: false,
        getDhisDataUploadError: null,
        dhisDataUpload: action.payload,
      };

    case DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_REQUEST:
      return {
        ...state,
        pushDhisDataUploadLoading: true,
        pushDhisDataUploadSuccess: false,
        pushDhisDataUploadFailure: false,
        pushDhisDataUploadError: null,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_FAILURE:
      return {
        ...state,
        pushDhisDataUploadLoading: false,
        pushDhisDataUploadSuccess: false,
        pushDhisDataUploadFailure: true,
        pushDhisDataUploadError: action.payload,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.PUSH_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        pushDhisDataUploadLoading: false,
        pushDhisDataUploadSuccess: true,
        pushDhisDataUploadFailure: false,
        pushDhisDataUploadError: null,
        pushDhisDataUpload: action.payload,
      };
    
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_REQUEST:
      return {
        ...state,
        getDhisLoginLoading: true,
        getDhisLoginSuccess: false,
        getDhisLoginFailure: false,
        getDhisLoginError: null,
        dhisLogin: [],
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_FAILURE:
      return {
        ...state,
        getDhisLoginLoading: false,
        getDhisLoginSuccess: false,
        getDhisLoginFailure: false,
        getDhisLoginError: action.payload,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGIN_SUCCESS:
      return {
        ...state,
        getDhisLoginLoading: false,
        getDhisLoginSuccess: true,
        getDhisLoginFailure: false,
        getDhisLoginError: null,
        dhisLogin: action.payload,
      };

    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_REQUEST:
      return {
        ...state,
        getDhisPushRequestLoading: true,
        getDhisPushRequestSuccess: false,
        getDhisPushRequestFailure: false,
        getDhisPushRequestError: null,
        pushRequests: [],
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_FAILURE:
      return {
        ...state,
        getDhisPushRequestLoading: false,
        getDhisPushRequestSuccess: false,
        getDhisPushRequestFailure: false,
        getDhisPushRequestError: action.payload,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_PUSH_REQUESTS_SUCCESS:
      return {
        ...state,
        getDhisPushRequestLoading: false,
        getDhisPushRequestSuccess: true,
        getDhisPushRequestFailure: false,
        getDhisPushRequestError: null,
        pushRequests: action.payload,
      };

    case DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_REQUEST:
      return {
        ...state,
        genrateDhisPushRequestLoading: true,
        genrateDhisPushRequestSuccess: false,
        genrateDhisPushRequestFailure: false,
        genrateDhisPushRequestError: null,
        genratePushRequest: [],
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_FAILURE:
      return {
        ...state,
        genrateDhisPushRequestLoading: false,
        genrateDhisPushRequestSuccess: false,
        genrateDhisPushRequestFailure: false,
        genrateDhisPushRequestError: action.payload,
      };
    case DHIS2_DATA_UPLOAD_REQUEST.GENRATE_DHIS_PUSH_REQUESTS_SUCCESS:
        return {
          ...state,
          genrateDhisPushRequestLoading: false,
          genrateDhisPushRequestSuccess: true,
          genrateDhisPushRequestFailure: false,
          genrateDhisPushRequestError: null,
          genratePushRequest: action.payload,
        };
    
        case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_REQUEST:
          return {
            ...state,
            getDhisLogsLoading: true,
            getDhisLogsSuccess: false,
            getDhisLogsFailure: false,
            getDhisLogsError: null,
            dhisLogs: [],
          };
        case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_FAILURE:
          return {
            ...state,
            getDhisLogsLoading: false,
            getDhisLogsSuccess: false,
            getDhisLogsFailure: false,
            getDhisLogsError: action.payload,
          };
        case DHIS2_DATA_UPLOAD_REQUEST.GET_DHIS_LOGS_SUCCESS:
          return {
            ...state,
            getDhisLogsLoading: false,
            getDhisLogsSuccess: true,
            getDhisLogsFailure: false,
            getDhisLogsError: null,
            dhisLogs: action.payload,
          };
    
        default:
      return state;
  }
};

import { CARE_GIVER_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getCareGiverListLoading: false,
  getCareGiverListSuccess: false,
  getCareGiverListFailure: false,
  getCareGiverListError: null,
  careGiverList: [],
  careGiverListCount: 0,

  getSingleCareGiverDetailLoading: false,
  getSingleCareGiverDetailSuccess: false,
  getSingleCareGiverDetailFailure: false,
  getSingleCareGiverDetailError: null,
  singleCareGiverDetail: null,

  addCaregiverLoading: false,
  addCaregiverSuccess: false,
  addCaregiverFailure: false,
  addCaregiverError: null,
  addCaregiver: null,

  updateCaregiverLoading: false,
  updateCaregiverSuccess: false,
  updateCaregiverFailure: false,
  updateCaregiverError: null,
  updateCaregiver: null,

  getDoctorLoading: false,
  getDoctorSuccess: false,
  getDoctorFailure: false,
  getDoctorError: null,
  getDoctor: null,
};

export const careGiverReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_REQUEST:
      return {
        ...state,
        getCareGiverListLoading: true,
        getCareGiverListSuccess: false,
        getCareGiverListFailure: false,
        getCareGiverListError: null,
        careGiverList: [],
        careGiverListCount: 0,
      };
    case CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_FAILURE:
      return {
        ...state,
        getCareGiverListLoading: false,
        getCareGiverListSuccess: false,
        getCareGiverListFailure: true,
        getCareGiverListError: action.payload,
      };
    case CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_SUCCESS:
      return {
        ...state,
        getCareGiverListLoading: false,
        getCareGiverListSuccess: true,
        getCareGiverListFailure: false,
        getCareGiverListError: null,
        careGiverList: action.payload,
        careGiverListCount: action.info.count,
      };
    case CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_REQUEST:
      return {
        ...state,
        getSingleCareGiverDetailLoading: true,
        getSingleCareGiverDetailSuccess: false,
        getSingleCareGiverDetailFailure: false,
        getSingleCareGiverDetailError: null,
        singleCareGiverDetail: null,
      };
    case CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_FAILURE:
      return {
        ...state,
        getSingleCareGiverDetailLoading: false,
        getSingleCareGiverDetailSuccess: false,
        getSingleCareGiverDetailFailure: true,
        getSingleCareGiverDetailError: action.payload,
      };
    case CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS:
      return {
        ...state,
        getSingleCareGiverDetailLoading: false,
        getSingleCareGiverDetailSuccess: true,
        getSingleCareGiverDetailFailure: false,
        getSingleCareGiverDetailError: null,
        singleCareGiverDetail: action.payload,
      };
    case CARE_GIVER_REQUEST.ADD_CARE_GIVER_REQUEST:
      return {
        ...state,
        addCaregiverLoading: true,
        addCaregiverSuccess: false,
        addCaregiverFailure: false,
        addCaregiverError: null,
        addCaregiver: null,
      };
    case CARE_GIVER_REQUEST.ADD_CARE_GIVER_FAILURE:
      return {
        ...state,
        addCaregiverLoading: false,
        addCaregiverSuccess: false,
        addCaregiverFailure: true,
        addCaregiverError: action.payload,
      };
    case CARE_GIVER_REQUEST.ADD_CARE_GIVER_SUCCESS:
      return {
        ...state,
        addCaregiverLoading: false,
        addCaregiverSuccess: true,
        addCaregiverFailure: false,
        addCaregiverError: null,
        addCaregiver: action.payload,
      };
    case CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_REQUEST:
      return {
        ...state,
        updateCaregiverLoading: true,
        updateCaregiverSuccess: false,
        updateCaregiverFailure: false,
        updateCaregiverError: null,
        updateCaregiver: null,
      };
    case CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_FAILURE:
      return {
        ...state,
        updateCaregiverLoading: false,
        updateCaregiverSuccess: false,
        updateCaregiverFailure: true,
        updateCaregiverError: action.payload,
      };
    case CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_SUCCESS:
      return {
        ...state,
        updateCaregiverLoading: false,
        updateCaregiverSuccess: true,
        updateCaregiverFailure: false,
        updateCaregiverError: null,
        updateCaregiver: action.payload,
      };
    case CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_REQUEST:
      return {
        ...state,
        getDoctorLoading: true,
        getDoctorSuccess: false,
        getDoctorFailure: false,
        getDoctorError: null,
        getDoctor: null,
      };
    case CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_FAILURE:
      return {
        ...state,
        getDoctorLoading: false,
        getDoctorSuccess: false,
        getDoctorFailure: true,
        getDoctorError: action.payload,
      };
    case CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_SUCCESS:
      return {
        ...state,
        getDoctorLoading: false,
        getDoctorSuccess: true,
        getDoctorFailure: false,
        getDoctorError: null,
        getDoctor: action.payload,
      };

    default:
      return state;
  }
};

import { ADMIN_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getAdminListLoading: false,
  getAdminListSuccess: false,
  getAdminListFailure: false,
  getAdminListError: null,
  AdminList: [],
  AdminListCount: 0,

  addEditAdminLoading: false,
  addEditAdminSuccess: false,
  addEditAdminFailure: false,
  addEditAdminError: null,

  getSingleAdminDetailsLoading: false,
  getSingleAdminDetailsSuccess: false,
  getSingleAdminDetailsFailure: false,
  getSingleAdminDetailsError: null,
  singleAdminDetails: null,

  setAdminActiveLoading: false,
  setAdminActiveSuccess: false,
  setAdminActiveFailure: false,
  setAdminActiveError: null,

  getAdminClinicListLoading: false,
  getAdminClinicListSuccess: false,
  getAdminClinicListFailure: false,
  getAdminClinicListError: null,
  adminClinicList: [],

  assignAdminClinicLoading: false,
  assignAdminClinicSuccess: false,
  assignAdminClinicFailure: false,
  assignAdminClinicError: null,
};

export const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_REQUEST.GET_ADMIN_LIST_REQUEST:
      return {
        ...state,
        getAdminListLoading: true,
        getAdminListSuccess: false,
        getAdminListFailure: false,
        getAdminListError: null,
        AdminList: [],
        AdminListCount: 0
      };
    case ADMIN_REQUEST.GET_ADMIN_LIST_FAILURE:
      return {
        ...state,
        getAdminListLoading: false,
        getAdminListSuccess: false,
        getAdminListFailure: true,
        getAdminListError: action.payload,
      };
    case ADMIN_REQUEST.GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        getAdminListLoading: false,
        getAdminListSuccess: true,
        getAdminListFailure: false,
        getAdminListError: null,
        AdminList: action.payload,
        AdminListCount: action.info.count
      };
    case ADMIN_REQUEST.ADD_EDIT_ADMIN_REQUEST:
      return {
        ...state,
        addEditAdminLoading: true,
        addEditAdminSuccess: false,
        addEditAdminFailure: false,
        addEditAdminError: null,
      };
    case ADMIN_REQUEST.ADD_EDIT_ADMIN_FAILURE:
      
      return {
        ...state,
        addEditAdminLoading: false,
        addEditAdminSuccess: false,
        addEditAdminFailure: true,
        addEditAdminError: action.payload,
      };
    case ADMIN_REQUEST.ADD_EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        addEditAdminLoading: false,
        addEditAdminSuccess: true,
        addEditAdminFailure: false,
        addEditAdminError: null,
      };
    case ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        getSingleAdminDetailsLoading: true,
        getSingleAdminDetailsSuccess: false,
        getSingleAdminDetailsFailure: false,
        getSingleAdminDetailsError: null,
        singleAdminDetails: null
      };
    case ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_FAILURE:
      
      return {
        ...state,
        getSingleAdminDetailsLoading: false,
        getSingleAdminDetailsSuccess: false,
        getSingleAdminDetailsFailure: true,
        getSingleAdminDetailsError: action.payload,
      };
    case ADMIN_REQUEST.GET_SINGLE_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleAdminDetailsLoading: false,
        getSingleAdminDetailsSuccess: true,
        getSingleAdminDetailsFailure: false,
        getSingleAdminDetailsError: null,
        singleAdminDetails: action.payload
      };
    case ADMIN_REQUEST.SET_ADMIN_ACTIVE_REQUEST:
      return {
        ...state,
        setAdminActiveLoading: true,
        setAdminActiveSuccess: false,
        setAdminActiveFailure: false,
        setAdminActiveError: null,
      };
    case ADMIN_REQUEST.SET_ADMIN_ACTIVE_FAILURE:
      return {
        ...state,
        setAdminActiveLoading: false,
        setAdminActiveSuccess: false,
        setAdminActiveFailure: true,
        setAdminActiveError: action.payload,
      };
    case ADMIN_REQUEST.SET_ADMIN_ACTIVE_SUCCESS:
      let updatedList = state.AdminList
      for (let i = 0; i < updatedList.length; i++) {
        if (updatedList[i].id === action.payload.adminId) {
          updatedList[i].isActive = action.payload.isActive
        }
      }
      return {
        ...state,
        setAdminActiveLoading: false,
        setAdminActiveSuccess: true,
        setAdminActiveFailure: false,
        setAdminActiveError: null,
        AdminList: updatedList
      };
    case ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_REQUEST:
      return {
        ...state,
        getAdminClinicListLoading: true,
        getAdminClinicListSuccess: false,
        getAdminClinicListFailure: false,
        getAdminClinicListError: null,
        adminClinicList: [],
      };
    case ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_FAILURE:
      return {
        ...state,
        getAdminClinicListLoading: false,
        getAdminClinicListSuccess: false,
        getAdminClinicListFailure: true,
        getAdminClinicListError: action.payload,
      };
    case ADMIN_REQUEST.GET_ADMIN_CLINIC_LIST_SUCCESS:
      return {
        ...state,
        getAdminClinicListLoading: false,
        getAdminClinicListSuccess: true,
        getAdminClinicListFailure: false,
        getAdminClinicListError: null,
        adminClinicList: action.payload,
      };
    case ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_REQUEST:
      return {
        ...state,
        assignAdminClinicLoading: true,
        assignAdminClinicSuccess: false,
        assignAdminClinicFailure: false,
        assignAdminClinicError: null,
      };
    case ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_FAILURE:
      return {
        ...state,
        assignAdminClinicLoading: false,
        assignAdminClinicSuccess: false,
        assignAdminClinicFailure: true,
        assignAdminClinicError: action.payload,
      };
    case ADMIN_REQUEST.ASSIGN_ADMIN_CLINIC_SUCCESS:
      return {
        ...state,
        assignAdminClinicLoading: false,
        assignAdminClinicSuccess: true,
        assignAdminClinicFailure: false,
        assignAdminClinicError: null,
      };
    default:
      return state;
  }
};

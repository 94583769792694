import {
  request,
  success,
  failure,
  CHILD_PRESCRIPTION_REQUEST,
} from "./utilities";
import {
  addChildPrescriptionApi,
  updateChildPrescriptionApi,
  getChildPrescriptionApi,
  getSingleChildPrescriptionDetailApi,
} from "../api";

export function addChildPrescriptionAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_REQUEST)
    );
    addChildPrescriptionApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateChildPrescriptionAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_REQUEST)
    );
    updateChildPrescriptionApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getChildPrescriptionAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_REQUEST)
    );
    getChildPrescriptionApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getSingleChildPrescriptionDetailAction(
  data,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_REQUEST
      )
    );
    getSingleChildPrescriptionDetailApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

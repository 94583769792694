import {
  request,
  success,
  failure,
  CHILD_OBSERVATION_REQUEST,
} from "./utilities";
import {
  addChildObservationApi,
  updateChildObservationApi,
  getChildObservationApi,
  getSingleChildObservationDetailApi,
} from "../api";

export function addChildObservationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_REQUEST));
    addChildObservationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateChildObservationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_REQUEST)
    );
    updateChildObservationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getChildObservationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_REQUEST));
    getChildObservationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function getSingleChildObservationDetailAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_REQUEST
      )
    );
    getSingleChildObservationDetailApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

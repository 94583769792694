import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Schedule List
 *
 * @return {*} Promise
 */
export function getDoctorScheduleListApi() {
    return axios.get(API_PATH.SCHEDULE.getDoctorScheduleList);
}
/**
 * Get Single Schedule Detail
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function getSingleScheduleDetailsApi(Id) {
    return axios.get(`${API_PATH.SCHEDULE.getSingleScheduleDetail}?Id=${Id}`);
}

/**
 * Add Schdeule
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function addScheduleApi(rec) {
    return axios.post(`${API_PATH.SCHEDULE.addSchedule}`, rec, {});
}

/**
 * Edit Schdeule
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function editScheduleApi(rec) {
    return axios.put(`${API_PATH.SCHEDULE.editSchedule}`, rec, {});
}

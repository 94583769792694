import axios from "axios";
import { API_PATH } from "../../../../redux/config";

export function login(email, password) {
  const params = {
    client_id: "OgowMobile",
    grant_type: "password",
    client_secret: "Ogow@123",
    username: email,
    password: password,
  };
  return axios.post(API_PATH.AUTH_API.LOGIN_URL, params);
}

export function logout() {
  return axios.post(API_PATH.AUTH_API.LOGOUT_URL);
}
export function register(email, fullname, username, password) {
  return axios.post(API_PATH.AUTH_API.REGISTER_URL, {
    email,
    fullname,
    username,
    password,
  });
}

export function changePassword(data) {
  return axios.post(API_PATH.AUTH_API.CHANGE_PASSWORD_URL, data);
}

export function requestPassword(data) {
  return axios.post(API_PATH.AUTH_API.REQUEST_PASSWORD_URL, data);
}

export function getUserByToken() {
   
  // Authorization head should be fulfilled in interceptor.
  return axios.get(API_PATH.AUTH_API.ME_URL);
}

import { SCHEDULE_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getDoctorScheduleListLoading: false,
  getDoctorScheduleListSuccess: false,
  getDoctorScheduleListFailure: false,
  getDoctorScheduleListError: null,
  DoctorScheduleList: [],
  DoctorScheduleListCount: 0,

  addScheduleLoading: false,
  addScheduleSuccess: false,
  addScheduleFailure: false,
  addScheduleError: null,

  editScheduleLoading: false,
  editScheduleSuccess: false,
  editScheduleFailure: false,
  editScheduleError: null,

  getSingleScheduleDetailsLoading: false,
  getSingleScheduleDetailsSuccess: false,
  getSingleScheduleDetailsFailure: false,
  getSingleScheduleDetailsError: null,
  singleScheduleDetails: null,

};

export const scheduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_REQUEST:
      return {
        ...state,
        getDoctorScheduleListLoading: true,
        getDoctorScheduleListSuccess: false,
        getDoctorScheduleListFailure: false,
        getDoctorScheduleListError: null,
        DoctorScheduleList: [],
        DoctorScheduleListCount: 0
      };
    case SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_FAILURE:
      return {
        ...state,
        getDoctorScheduleListLoading: false,
        getDoctorScheduleListSuccess: false,
        getDoctorScheduleListFailure: true,
        getDoctorScheduleListError: action.payload,
      };
    case SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        getDoctorScheduleListLoading: false,
        getDoctorScheduleListSuccess: true,
        getDoctorScheduleListFailure: false,
        getDoctorScheduleListError: null,
        DoctorScheduleList: action.payload,
        // DoctorScheduleListCount: action.info.count
      };
    case SCHEDULE_REQUEST.ADD_SCHEDULE_REQUEST:
      return {
        ...state,
        addScheduleLoading: true,
        addScheduleSuccess: false,
        addScheduleFailure: false,
        addScheduleError: null,
      };
    case SCHEDULE_REQUEST.ADD_SCHEDULE_FAILURE:
      return {
        ...state,
        addScheduleLoading: false,
        addScheduleSuccess: false,
        addScheduleFailure: true,
        addScheduleError: action.payload,
      };
    case SCHEDULE_REQUEST.ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        addScheduleLoading: false,
        addScheduleSuccess: true,
        addScheduleFailure: false,
        addScheduleError: null,
      };
    case SCHEDULE_REQUEST.EDIT_SCHEDULE_REQUEST:
      return {
        ...state,
        editScheduleLoading: true,
        editScheduleSuccess: false,
        editScheduleFailure: false,
        editScheduleError: null,
      };
    case SCHEDULE_REQUEST.EDIT_SCHEDULE_FAILURE:
      return {
        ...state,
        editScheduleLoading: false,
        editScheduleSuccess: false,
        editScheduleFailure: true,
        editScheduleError: action.payload,
      };
    case SCHEDULE_REQUEST.EDIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        editScheduleLoading: false,
        editScheduleSuccess: true,
        editScheduleFailure: false,
        editScheduleError: null,
      };
    case SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        getSingleScheduleDetailsLoading: true,
        getSingleScheduleDetailsSuccess: false,
        getSingleScheduleDetailsFailure: false,
        getSingleScheduleDetailsError: null,
        singleScheduleDetails: null
      };
    case SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_FAILURE:
      return {
        ...state,
        getSingleScheduleDetailsLoading: false,
        getSingleScheduleDetailsSuccess: false,
        getSingleScheduleDetailsFailure: true,
        getSingleScheduleDetailsError: action.payload,
      };
    case SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleScheduleDetailsLoading: false,
        getSingleScheduleDetailsSuccess: true,
        getSingleScheduleDetailsFailure: false,
        getSingleScheduleDetailsError: null,
        singleScheduleDetails: action.payload
      };
    default:
      return state;
  }
};

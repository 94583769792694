import { CHILD_OBSERVATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addChildObservationLoading: false,
  addChildObservationSuccess: false,
  addChildObservationFailure: false,
  addChildObservationError: null,
  addChildObservation: {},

  updateChildObservationLoading: false,
  updateChildObservationSuccess: false,
  updateChildObservationFailure: false,
  updateChildObservationError: null,
  updateChildObservation: {},

  getChildObservationLoading: false,
  getChildObservationSuccess: false,
  getChildObservationFailure: false,
  getChildObservationError: null,
  childObservation: [],

  getSingleChildObservationDetailLoading: false,
  getSingleChildObservationDetailSuccess: false,
  getSingleChildObservationDetailFailure: false,
  getSingleChildObservationDetailError: null,
  singleChildObservationDetail: [],
};

export const childObservationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_REQUEST:
      return {
        ...state,
        addChildObservationLoading: true,
        addChildObservationSuccess: false,
        addChildObservationFailure: false,
        addChildObservationError: null,
      };
    case CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_FAILURE:
      return {
        ...state,
        addChildObservationLoading: false,
        addChildObservationSuccess: false,
        addChildObservationFailure: true,
        addChildObservationError: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.ADD_CHILD_OBSERVATION_SUCCESS:
      return {
        ...state,
        addChildObservationLoading: false,
        addChildObservationSuccess: true,
        addChildObservationFailure: false,
        addChildObservationError: null,
      };
    case CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_REQUEST:
      return {
        ...state,
        updateChildObservationLoading: true,
        updateChildObservationSuccess: false,
        updateChildObservationFailure: false,
        updateChildObservationError: null,
        updateChildObservation: {},
      };
    case CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_FAILURE:
      return {
        ...state,
        updateChildObservationLoading: false,
        updateChildObservationSuccess: false,
        updateChildObservationFailure: true,
        updateChildObservationError: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.UPDATE_CHILD_OBSERVATION_SUCCESS:
      return {
        ...state,
        updateChildObservationLoading: false,
        updateChildObservationSuccess: true,
        updateChildObservationFailure: false,
        updateChildObservationError: null,
        updateChildObservation: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_REQUEST:
      return {
        ...state,
        getChildObservationLoading: true,
        getChildObservationSuccess: false,
        getChildObservationFailure: false,
        getChildObservationError: null,
        childObservation: [],
      };
    case CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_FAILURE:
      return {
        ...state,
        getChildObservationLoading: false,
        getChildObservationSuccess: false,
        getChildObservationFailure: true,
        getChildObservationError: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.GET_CHILD_OBSERVATION_SUCCESS:
      return {
        ...state,
        getChildObservationLoading: false,
        getChildObservationSuccess: true,
        getChildObservationFailure: false,
        getChildObservationError: null,
        childObservation: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_REQUEST:
      return {
        ...state,
        getSingleChildObservationDetailLoading: true,
        getSingleChildObservationDetailSuccess: false,
        getSingleChildObservationDetailFailure: false,
        getSingleChildObservationDetailError: null,
        singleChildObservationDetail: [],
      };
    case CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_FAILURE:
      return {
        ...state,
        getSingleChildObservationDetailLoading: false,
        getSingleChildObservationDetailSuccess: false,
        getSingleChildObservationDetailFailure: true,
        getSingleChildObservationDetailError: action.payload,
      };
    case CHILD_OBSERVATION_REQUEST.GET_SINGLE_CHILD_OBSERVATION_DETAIL_SUCCESS:
      return {
        ...state,
        getSingleChildObservationDetailLoading: false,
        getSingleChildObservationDetailSuccess: true,
        getSingleChildObservationDetailFailure: false,
        getSingleChildObservationDetailError: null,
        singleChildObservationDetail: action.payload,
      };
    default:
      return state;
  }
};

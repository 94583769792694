import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get General Dashboard Stats Data
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAncStatsApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getStats, {
    params: filters,
  });
}

export function getVaccinationChartApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getVaccinationChart, {
    params: filters,
  });
}

export function getCompletedVaccinationApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getCompletedVaccination, {
    params: filters,
  });
}

export function getIncompletedVaccinationApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getIncompletedVaccination, {
    params: filters,
  });
}

export function getMissedVaccinationApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getMissedVaccination, {
    params: filters,
  });
}

export function getMotherVaccinationApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.getMotherVaccination, {
    params: filters,
  });
}

export function getMotherAncDetailApi(data) {
  return axios.get(API_PATH.ANC_DASHBOARD.getMotherANCDetails, {
    params: data,
  });
}

export function exportAncStatsExcelApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.exportAncStatsExcel, {
    params: filters,
    headers: {
      "Access-Control-Expose-Headers": "Content-Disposition",
    },
    responseType: "blob",
  });
}

export function exportMissedVaccinationExcelApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.exportMissedVaccinationExcel, {
    params: filters,
    responseType: "blob",
  });
}

export function exportMotherVaccinationExcelApi(filters) {
  return axios.get(API_PATH.ANC_DASHBOARD.exportMotherVaccinationExcel, {
    params: filters,
    responseType: "blob",
  });
}

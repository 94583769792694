import {
  request,
  success,
  failure,
  DHIS2_FACILITY_CONFIGURATION_REQUEST,
} from "./utilities";
import {
  addFacilityConfigurationApi,
  updateFacilityConfigurationApi,
  deleteFacilityConfigurationApi,
  getFacilityConfigurationMappingListApi,
  addDHISFacilityApi,
  getByIdDHISFacilityApi,
  updateDHISFacilityApi,
  deleteDHISFacilityApi,
  getDHISFacilitiesListingApi,
} from "../api";

export function getDHISFacilitiesListingAction(data) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_REQUEST
      )
    );
    getDHISFacilitiesListingApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_FAILURE,
              error.message
            )
          );
        }
      )

  };
}
export function deleteDHISFacilityAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_REQUEST
      )
    );
    deleteDHISFacilityApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function updateDHISFacilityAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_REQUEST
      )
    );
    updateDHISFacilityApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function getByIdDHISFacilityAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_REQUEST
      )
    );
    getByIdDHISFacilityApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function addDHISFacilityAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_REQUEST)
    );
    addDHISFacilityApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function addFacilityConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_REQUEST
      )
    );
    addFacilityConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateFacilityConfigurationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_REQUEST
      )
    );
    updateFacilityConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function deleteFacilityConfigurationAction(
  data,
  modalClose,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_REQUEST
      )
    );
    deleteFacilityConfigurationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_SUCCESS,
                data.id
              )
            );
            if (modalClose) {
              modalClose();
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getFacilityConfigurationMappingListAction(
  data,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(
        DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_REQUEST
      )
    );
    getFacilityConfigurationMappingListApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_SUCCESS,
                response.data.data,
                {
                  count: response.data.totalRecords
                }

              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Prescription
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addChildPrescriptionApi(data) {
  return axios.post(API_PATH.CHILD_PRESCRIPTION.addChildPrescription, data);
}

/**
 * Get Update Prescription
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateChildPrescriptionApi(data) {
  return axios.put(API_PATH.CHILD_PRESCRIPTION.updateChildPrescription, data);
}
/**
 * Get Prescription List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getChildPrescriptionApi(data) {
  return axios.get(API_PATH.CHILD_PRESCRIPTION.getChildPrescription, {
    params: data,
  });
}

/**
 * Get Single Prescription
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getSingleChildPrescriptionDetailApi(data) {
  return axios.get(API_PATH.CHILD_PRESCRIPTION.getSingleChildPrescription, {
    params: data,
  });
}

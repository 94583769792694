import { CHILD_IMMUNIZATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getPendingVaccineLoading: false,
  getPendingVaccineSuccess: false,
  getPendingVaccineFailure: false,
  getPendingVaccineError: null,
  pendingVaccine: [],

  addImmunizationLoading: false,
  addImmunizationSuccess: false,
  addImmunizationFailure: false,
  addImmunizationError: null,
  addImmunization: {},

  updateImmunizationLoading: false,
  updateImmunizationSuccess: false,
  updateImmunizationFailure: false,
  updateImmunizationError: null,
  updateImmunization: {},

  getRecordedImmunizationsLoading: false,
  getRecordedImmunizationsSuccess: false,
  getRecordedImmunizationsFailure: false,
  getRecordedImmunizationsError: null,
  getRecordedImmunizations: [],

  getSingleImmunizationDetailLoading: false,
  getSingleImmunizationDetailSuccess: false,
  getSingleImmunizationDetailFailure: false,
  getSingleImmunizationDetailError: null,
  getSingleImmunizationDetail: null,
};

export const childImmunizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_REQUEST:
      return {
        ...state,
        getPendingVaccineLoading: true,
        getPendingVaccineSuccess: false,
        getPendingVaccineFailure: false,
        getPendingVaccineError: null,
        pendingVaccine: [],
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_SUCCESS:
      return {
        ...state,
        getPendingVaccineLoading: false,
        getPendingVaccineSuccess: true,
        getPendingVaccineFailure: false,
        getPendingVaccineError: null,
        pendingVaccine: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_FAILURE:
      return {
        ...state,
        getPendingVaccineLoading: true,
        getPendingVaccineSuccess: false,
        getPendingVaccineFailure: true,
        getPendingVaccineError: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_REQUEST:
      return {
        ...state,
        addImmunizationLoading: true,
        addImmunizationSuccess: false,
        addImmunizationFailure: false,
        addImmunizationError: null,
        addImmunization: {},
      };
    case CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_FAILURE:
      return {
        ...state,
        addImmunizationLoading: false,
        addImmunizationSuccess: false,
        addImmunizationFailure: true,
        addImmunizationError: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        addImmunizationLoading: false,
        addImmunizationSuccess: true,
        addImmunizationFailure: false,
        addImmunizationError: null,
        addImmunization: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_REQUEST:
      return {
        ...state,
        updateImmunizationLoading: true,
        updateImmunizationSuccess: false,
        updateImmunizationFailure: false,
        updateImmunizationError: null,
        updateImmunization: {},
      };
    case CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_FAILURE:
      return {
        ...state,
        updateImmunizationLoading: false,
        updateImmunizationSuccess: false,
        updateImmunizationFailure: true,
        updateImmunizationError: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        updateImmunizationLoading: false,
        updateImmunizationSuccess: true,
        updateImmunizationFailure: false,
        updateImmunizationError: null,
        updateImmunization: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_REQUEST:
      return {
        ...state,
        getRecordedImmunizationsLoading: true,
        getRecordedImmunizationsSuccess: false,
        getRecordedImmunizationsFailure: false,
        getRecordedImmunizationsError: null,
        getRecordedImmunizations: [],
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_FAILURE:
      return {
        ...state,
        getRecordedImmunizationsLoading: false,
        getRecordedImmunizationsSuccess: false,
        getRecordedImmunizationsFailure: true,
        getRecordedImmunizationsError: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_SUCCESS:
      return {
        ...state,
        getRecordedImmunizationsLoading: false,
        getRecordedImmunizationsSuccess: true,
        getRecordedImmunizationsFailure: false,
        getRecordedImmunizationsError: null,
        getRecordedImmunizations: action.payload,
      };

    case CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_REQUEST:
      return {
        ...state,
        getSingleImmunizationDetailLoading: true,
        getSingleImmunizationDetailSuccess: false,
        getSingleImmunizationDetailFailure: false,
        getSingleImmunizationDetailError: null,
        getSingleImmunizationDetail: null,
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_FAILURE:
      return {
        ...state,
        getSingleImmunizationDetailLoading: false,
        getSingleImmunizationDetailSuccess: false,
        getSingleImmunizationDetailFailure: true,
        getSingleImmunizationDetailError: action.payload,
      };
    case CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_SUCCESS:
      return {
        ...state,
        getSingleImmunizationDetailLoading: false,
        getSingleImmunizationDetailSuccess: true,
        getSingleImmunizationDetailFailure: false,
        getSingleImmunizationDetailError: null,
        getSingleImmunizationDetail: action.payload,
      };
    default:
      return state;
  }
};

import { DHIS2_DATAELEMENT_CONFIGURATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addDataelementConfigurationLoading: false,
  addDataelementConfigurationSuccess: false,
  addDataelementConfigurationFailure: false,
  addDataelementConfigurationError: null,
  addDataelementConfiguration: {},

  updateDataelementConfigurationLoading: false,
  updateDataelementConfigurationSuccess: false,
  updateDataelementConfigurationFailure: false,
  updateDataelementConfigurationError: null,
  updateDataelementConfiguration: {},

  deleteDataelementConfigurationLoading: false,
  deleteDataelementConfigurationSuccess: false,
  deleteDataelementConfigurationFailure: false,
  deleteDataelementConfigurationError: null,
  deleteDataelementConfiguration: {},

  getDataelementConfigurationLoading: false,
  getDataelementConfigurationSuccess: false,
  getDataelementConfigurationFailure: false,
  getDataelementConfigurationError: null,
  dataelementConfiguration: [],
  dataelementCount:0,

  getDataElementMappingSelectListLoading: false,
  getDataElementMappingSelectListSuccess: false,
  getDataElementMappingSelectListFailure: false,
  getDataElementMappingSelectListError: null,
  dataElementMappingSelectList: [],
};

export const dhisDataElementConfigReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_REQUEST:
      return {
        ...state,
        addDataelementConfigurationLoading: true,
        addDataelementConfigurationSuccess: false,
        addDataelementConfigurationFailure: false,
        addDataelementConfigurationError: null,
        addDataelementConfiguration: {},
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_FAILURE:
      return {
        ...state,
        addDataelementConfigurationLoading: false,
        addDataelementConfigurationSuccess: false,
        addDataelementConfigurationFailure: true,
        addDataelementConfigurationError: action.payload,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.ADD_DATAELEMENT_CONFIGURATION_SUCCESS:
      let updatedDataElementConfiguation = [
        ...state.dataelementConfiguration,
        ...action.payload.data,
      ];
      return {
        ...state,
        addDataelementConfigurationLoading: false,
        addDataelementConfigurationSuccess: true,
        addDataelementConfigurationFailure: false,
        addDataelementConfigurationError: null,
        addDataelementConfiguration: action.payload,
        dataelementConfiguration: updatedDataElementConfiguation,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_REQUEST:
      return {
        ...state,
        updateDataelementConfigurationLoading: true,
        updateDataelementConfigurationSuccess: false,
        updateDataelementConfigurationFailure: false,
        updateDataelementConfigurationError: null,
        updateDataelementConfiguration: {},
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_FAILURE:
      return {
        ...state,
        updateDataelementConfigurationLoading: false,
        updateDataelementConfigurationSuccess: false,
        updateDataelementConfigurationFailure: true,
        updateDataelementConfigurationError: action.payload,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.UPDATE_DATAELEMENT_CONFIGURATION_SUCCESS:
      let updatedUpdateDataElementConfiguation = [
        ...state.dataelementConfiguration,
      ];
      let findIndex = updatedUpdateDataElementConfiguation.findIndex(
        (item) => item.id === action.payload.id
      );
      updatedUpdateDataElementConfiguation[findIndex] = action.payload;
      return {
        ...state,
        updateDataelementConfigurationLoading: false,
        updateDataelementConfigurationSuccess: true,
        updateDataelementConfigurationFailure: false,
        updateDataelementConfigurationError: null,
        updateDataelementConfiguration: action.payload,
        dataelementConfiguration: updatedUpdateDataElementConfiguation,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_REQUEST:
      return {
        ...state,
        deleteDataelementConfigurationLoading: true,
        deleteDataelementConfigurationSuccess: false,
        deleteDataelementConfigurationFailure: false,
        deleteDataelementConfigurationError: null,
        deleteDataelementConfiguration: {},
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_FAILURE:
      return {
        ...state,
        deleteDataelementConfigurationLoading: false,
        deleteDataelementConfigurationSuccess: false,
        deleteDataelementConfigurationFailure: true,
        deleteDataelementConfigurationError: action.payload,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.DELETE_DATAELEMENT_CONFIGURATION_SUCCESS:
      let deletePermanentDataelementConfiguration = state.dataelementConfiguration.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        deleteDataelementConfigurationLoading: false,
        deleteDataelementConfigurationSuccess: true,
        deleteDataelementConfigurationFailure: false,
        deleteDataelementConfigurationError: null,
        deleteDataelementConfiguration: action.payload,
        dataelementConfiguration: deletePermanentDataelementConfiguration,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_REQUEST:
      return {
        ...state,
        getDataelementConfigurationLoading: true,
        getDataelementConfigurationSuccess: false,
        getDataelementConfigurationFailure: false,
        getDataelementConfigurationError: null,
        dataelementConfiguration: [],
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_FAILURE:
      return {
        ...state,
        getDataelementConfigurationLoading: false,
        getDataelementConfigurationSuccess: false,
        getDataelementConfigurationFailure: true,
        getDataelementConfigurationError: action.payload,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        getDataelementConfigurationLoading: false,
        getDataelementConfigurationSuccess: true,
        getDataelementConfigurationFailure: false,
        getDataelementConfigurationError: null,
        dataelementConfiguration: action.payload,
        dataelementCount: action.info.count,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_REQUEST:
      return {
        ...state,
        getDataElementMappingSelectListLoading: true,
        getDataElementMappingSelectListSuccess: false,
        getDataElementMappingSelectListFailure: false,
        getDataElementMappingSelectListError: null,
        dataElementMappingSelectList: [],
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_FAILURE:
      return {
        ...state,
        getDataElementMappingSelectListLoading: false,
        getDataElementMappingSelectListSuccess: false,
        getDataElementMappingSelectListFailure: true,
        getDataElementMappingSelectListError: action.payload,
      };
    case DHIS2_DATAELEMENT_CONFIGURATION_REQUEST.GET_DATAELEMENT_MAPPING_SELECT_LIST_SUCCESS:
      return {
        ...state,
        getDataElementMappingSelectListLoading: false,
        getDataElementMappingSelectListSuccess: true,
        getDataElementMappingSelectListFailure: false,
        getDataElementMappingSelectListError: null,
        dataElementMappingSelectList: action.payload,
      };
    default:
      return state;
  }
};

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Admin List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllLogginActivityApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityList, {
        params: filters,
    });
}
export function loginAccountWith2FaApi(req) {
    return axios.post(API_PATH.AUTH_API.LOGIN_WITH_2FA, req
    );
}
export function getLoggingActivityAnalyticsApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityAnalytics, {
        params: filters,
    });
}
export function getAllTallyValidationApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getAllTallyValidation, {
        params: filters,
    });
}
export function getLoggingActivityExportApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityExport, {
        params: filters,
        responseType: "blob",
    });
}
export function getLoggingActivityChartApi(filters) {
    return axios.get(API_PATH.LOGGING_ACTIVITY.getLoggingActivityChart, {
        params: filters,
    });
}